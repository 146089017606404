import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import PageHeaderMedia from "../components/PageHeaderMedia"
import { Layout } from "../components/Layout"
// import Recruitment from "../components/pages/recruitment"
import { PageContent } from "../features/recruitment-page/components"
import { useLocationData } from "../hooks/locationHook"

export default function RecruitmentPage({ location }) {
  const { data } = useStaticQuery(query)
  useLocationData(location.pathname)
  // const francoisData = data.recruitCompany[0]
  // const nbkData = data.recruitCompany[1]
  // const menshokuData = data.recruitCompany[2]
  return (
    <Layout>
      <PageHeaderMedia
        fluid={data.headerMedia.childImageSharp.fluid}
        pageTitles={data.headerTitle}
      />
      {/* <Recruitment
        francois={francoisData}
        nbk={nbkData}
        menshoku={menshokuData}
      /> */}
      <PageContent />
    </Layout>
  )
}

export const query = graphql`
  {
    data: strapiRecruitmentPage {
      headerTitle
      headerMediaAlt
      headerMedia {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      recruitCompany {
        id
        companyName
        recruitMessage
        linkUrl
        linkText
        recruitmentList {
          id
          recruitCategory
        }
      }
    }
  }
`
